import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import './styles.scss';
import MonthlyIncome from './Components/MonthlyIncome';
import DiscountConceptsCard from './Components/DiscountConceptsCard';
import ToPayCard from './Components/ToPayCard';
import GainsCard from './Components/GainsCard';
import TopTransactionsCard from './Components/TopTransactions';
import WelcomeCard from './Components/WelcomeCard';
import DebtorTable from './Components/DebtorTable';
import { TopStudentsDiscount } from './Components/TopStudentsDiscount';
import TabsComponent from '../../components/tabs';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import {
    selectKpiStateConceptsWithoutPayment,
    selectKpiStateTotalGains,
} from '../../store/home/kpis';
import { selectKpiStateLastStudentWithDiscount } from '../../store/home/cards';
import { selectConceptsWithDiscount } from '../../store/home/charts';
import {
    selectStateLastStudentsWithPayments,
    selectStateMonthlyPayments,
} from '../../store/home/tables';
import { selectStateTopDebtors } from '../../store/home/tops';

const DashboardPayments = () => {
    const [currentTab, setCurrentTab] = useState('inicio');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const StateTotalGains = useSelector(selectKpiStateTotalGains);
    const StateConceptsWithoutPayments = useSelector(
        selectKpiStateConceptsWithoutPayment
    );
    const stateLastStudentWithDiscount = useSelector(
        selectKpiStateLastStudentWithDiscount
    );
    const stateConceptsWithDiscount = useSelector(selectConceptsWithDiscount);
    const stateMonthlyPayments = useSelector(selectStateMonthlyPayments);
    const stateLastStudentsWithPayments = useSelector(
        selectStateLastStudentsWithPayments
    );
    const stateTopDebtors = useSelector(selectStateTopDebtors);

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                setLoading={setLoading}
            />
            {loading ? (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={500}
                        sx={{ mb: 2 }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 2,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    {/* Welcome Card */}
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                mt: 7,
                            }}>
                            {StateTotalGains === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={200}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <WelcomeCard />
                                </Box>
                            )}
                        </Grid>

                        {/* Revenue and Transactions */}
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2.5}
                            sx={{ mb: 2, mt: 5 }}>
                            {StateTotalGains === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={200}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <GainsCard />
                            )}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2.5}
                            sx={{ mb: 2, mt: 5 }}>
                            {StateConceptsWithoutPayments === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={200}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <ToPayCard />
                            )}
                        </Grid>
                    </Grid>

                    {/* Transactions, New Registrations, and Discount Concepts */}
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                mb: 2,
                                mt: 2,
                            }}>
                            {stateLastStudentsWithPayments === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={600}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <TopTransactionsCard
                                        sx={{ height: '100%' }}
                                    />
                                </Box>
                            )}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                mb: 2,
                                mt: 2,
                            }}>
                            {stateMonthlyPayments === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={600}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <MonthlyIncome sx={{ height: '100%' }} />
                                </Box>
                            )}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'stretch',
                                mb: 2,
                                mt: 2,
                            }}>
                            {stateConceptsWithDiscount === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={600}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <DiscountConceptsCard
                                        sx={{ height: '100%' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    {/* Debtor Table and Top Students Discount */}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} sx={{ mb: 2, mt: 2 }}>
                            {stateLastStudentWithDiscount === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={600}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <TopStudentsDiscount />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ mb: 2, mt: 2 }}>
                            {stateTopDebtors === 'pending' ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={600}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                    }}
                                />
                            ) : (
                                <DebtorTable />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default DashboardPayments;
