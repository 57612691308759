import React, { useEffect, useState } from 'react';
import {
    Tabs,
    Tab,
    Divider,
    Tooltip,
    Grid,
    Stack,
    Box,
    Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '../../hooks';
import {
    fetchAgreements,
    invalidateAgreements,
    selectAgreementsFetch,
} from '../store/agreements/fetchSlice';
import {
    fetchConcepts,
    invalidateConcepts,
    selectConceptsFetch,
} from '../store/concepts/fetchSlice';
import {
    fetchDiscounts,
    invalidateDiscounts,
    selectDiscountsFetch,
} from '../store/discounts/fetchSlice';
import {
    fetchIncome,
    invalidateIncome,
    selectIncomeFetch,
} from '../store/income/fetchSlice';
import { selectSurcharges } from '../store/surcharges/selectors';
import {
    fetchSurcharges,
    invalidateSurcharges,
    selectFetchSurcharges,
} from '../store/surcharges/fetchSlice';
import {
    fetchTopDebtors,
    invalidate,
    invalidateTops,
    selectTopFetchState,
} from '../store/home/tops';
import {
    fetchCardLastStudentWithDiscount,
    invalidateCards,
    selectCardFetchState,
} from '../store/home/cards';
import {
    fetchConceptsWithDiscount,
    invalidateCharts,
    selectChartFetchState,
} from '../store/home/charts';
import {
    fetchLastStudentsWithPayments,
    fetchLastStudentWithDiscount,
    fetchMonthlyPaymentsCount,
    invalidateTables,
    selectTableFetchLastStudentsWithPayments,
    selectTableFetchLastStudentWithDiscount,
    selectTableFetchMonthlyPayments,
    selectTableFetchState,
} from '../store/home/tables';
import {
    fetchConceptsWithoutPayment,
    fetchTotalDiscountLoss,
    fetchTotalGains,
    invalidateKpis,
    selectKpiFetchConceptsWithoutPayment,
    selectKpiFetchState,
    selectKpiFetchTotalDiscountsLoss,
    selectKpiFetchTotalGains,
} from '../store/home/kpis';

const TabsComponent = ({ currentTab, onTabChange, setLoading }) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;

    const fetchState = useSelector((state) => {
        switch (currentTab) {
            case 'inicio':
                return {
                    
                    selectTopFetchState: selectTopFetchState(state),
                    selectCardFetchState: selectCardFetchState(state),
                    selectChartFetchState: selectChartFetchState(state),
                    selectTableFetchLastStudentsWithPayments: selectTableFetchLastStudentsWithPayments(state),
                    selectTableFetchLastStudentWithDiscount: selectTableFetchLastStudentWithDiscount(state),
                    selectTableFetchMonthlyPayments: selectTableFetchMonthlyPayments(state),
                    selectKpiFetchConceptsWithoutPayment: selectKpiFetchConceptsWithoutPayment(state),
                    selectKpiFetchTotalGains: selectKpiFetchTotalGains(state),
                    selectKpiFetchTotalDiscountsLoss: selectKpiFetchTotalDiscountsLoss(state),


                };
            case 'convenios':
                return selectAgreementsFetch(state);
            case 'conceptos':
                return selectConceptsFetch(state);
            case 'descuentos':
                return selectDiscountsFetch(state);
            case 'pagos':
                return selectIncomeFetch(state);
            case 'recargos':
                return selectFetchSurcharges(state);
            default:
                return null;
        }
    });

    useEffect(() => {
        // Cargar datos cuando la pestaña se activa
        switch (currentTab) {
            case 'inicio':
                dispatch(fetchTopDebtors(schoolId));
                dispatch(fetchCardLastStudentWithDiscount(schoolId));
                dispatch(fetchConceptsWithDiscount(schoolId));
                dispatch(fetchMonthlyPaymentsCount(schoolId));
                dispatch(fetchLastStudentsWithPayments(schoolId));
                dispatch(fetchLastStudentWithDiscount(schoolId));
                dispatch(fetchTotalDiscountLoss(schoolId));
                dispatch(fetchTotalGains(schoolId));
                dispatch(fetchConceptsWithoutPayment(schoolId));

                break;
            case 'convenios':
                dispatch(fetchAgreements(schoolId));
                break;
            case 'conceptos':
                dispatch(fetchConcepts(schoolId));
                break;
            case 'descuentos':
                dispatch(fetchDiscounts(schoolId));
                break;
            case 'pagos':
                dispatch(fetchIncome(schoolId));
                break;
            case 'recargos':
                dispatch(fetchSurcharges(schoolId));
                break;
            default:
                break;
        }
    }, [currentTab, dispatch, schoolId]);

    useEffect(() => {
        setLoading(fetchState?.status === 'pending');
    }, [fetchState, setLoading]);

    const handleSync = () => {
        switch (currentTab) {
            case 'inicio':
                dispatch(invalidate());
                dispatch(invalidateTables());
                dispatch(invalidateCards());
                dispatch(invalidateKpis());
                dispatch(invalidateCharts());
                dispatch(fetchTopDebtors(schoolId));
                dispatch(fetchCardLastStudentWithDiscount(schoolId));
                dispatch(fetchConceptsWithDiscount(schoolId));
                dispatch(fetchMonthlyPaymentsCount(schoolId));
                dispatch(fetchLastStudentsWithPayments(schoolId));
                dispatch(fetchLastStudentWithDiscount(schoolId));
                dispatch(fetchTotalDiscountLoss(schoolId));
                dispatch(fetchTotalGains(schoolId));
                dispatch(fetchConceptsWithoutPayment(schoolId));
                break;
            case 'convenios':
                dispatch(invalidateAgreements());
                dispatch(fetchAgreements(schoolId));
                break;
            case 'conceptos':
                dispatch(invalidateConcepts());
                dispatch(fetchConcepts(schoolId));
                break;
            case 'descuentos':
                dispatch(invalidateDiscounts());
                dispatch(fetchDiscounts(schoolId));
                break;
            case 'pagos':
                dispatch(invalidateIncome());
                dispatch(fetchIncome(schoolId));
                break;
            case 'recargos':
                dispatch(invalidateSurcharges());
                dispatch(fetchSurcharges(schoolId));
                break;
            default:
                break;
        }
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },

                flexGrow: 1,
            }}>
            <>
                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Tabs
                            value={currentTab}
                            onChange={onTabChange}
                            aria-label="tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ width: '100%' }}>
                            <Tab
                                label="Inicio"
                                value="inicio"
                                component={Link}
                                to="/Finanzas/inicio"
                            />
                            <Tab
                                label="Pagos"
                                value="pagos"
                                component={Link}
                                to="/Finanzas/pagos"
                            />
                            <Tab
                                label="Convenios"
                                value="convenios"
                                component={Link}
                                to="/Finanzas/convenios"
                            />
                            <Tab
                                label="Conceptos"
                                value="conceptos"
                                component={Link}
                                to="/Finanzas/conceptos"
                            />
                            <Tab
                                label="Descuentos"
                                value="descuentos"
                                component={Link}
                                to="/Finanzas/descuentos"
                            />
                            <Tab
                                label="Recargos"
                                value="recargos"
                                component={Link}
                                to="/Finanzas/recargos"
                            />
                        </Tabs>
                        <Divider textAlign="left" />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Stack
                            spacing={2}
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-end"
                            alignItems="center">
                            <Tooltip title="Sincronizar información">
                                <LoadingButton
                                    onClick={handleSync}
                                    loadingPosition="start"
                                    variant="contained"
                                    size="small"
                                    startIcon={<SyncIcon />}
                                    loading={fetchState?.status === 'pending'}>
                                    Sincronizar
                                </LoadingButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </>
        </Box>
    );
};

export default TabsComponent;
