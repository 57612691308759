import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentAgreements } from './selectors';
import {
    setAllAgreements,
    upsertManyAgreements,
} from '../../../store/slices/entities/payments/agreements';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyPartialities } from '../../../store/slices/entities/payments/partialities';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Thunk para realizar la petición asincrónica de acuerdos de pago
export const fetchAgreements = createAsyncThunk(
    'agreements/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const [
                fetchPaymentsResponse,
                fetchConceptsResponse,
                fetchCatConcepts,
                fetchUsers,
                fetchStudents,
                fetchAgreement,
            ] = await Promise.all([
                Connection.getPaymentsBySchool(schoolId),
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getUsersBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const agreements = fetchAgreement.data.data;
            const payments = fetchPaymentsResponse.data.data;
            const concepts = fetchConceptsResponse.data.data;
            const catConcepts = fetchCatConcepts.data.data;
            const users = fetchUsers.data.data;
            const students = fetchStudents.data.data;

            thunkAPI.dispatch(upsertManyAgreements(agreements));
            thunkAPI.dispatch(upsertManyPayments(payments));
            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyUsers(users));
            thunkAPI.dispatch(upsertManyStudents(students));

            return {
                agreements,
                payments,
                concepts,
                catConcepts,
                users,
                students,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectAgreementsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const FetchCreate = createAsyncThunk(
    'agreements/fetch/create',

    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [fetchConcepts, fetchStudents] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
            ]);

            const concepts = fetchConcepts.data.data;
            const students = fetchStudents.data.data;

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            return { concepts, students };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Slice para manejar el estado de la petición
const AgreementsFetchSlice = createSlice({
    name: 'agreementsFetch',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgreements.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchAgreements.fulfilled, (state, action) => {
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            .addCase(fetchAgreements.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            });
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { invalidate: invalidateAgreements } =
    AgreementsFetchSlice.actions;
export default AgreementsFetchSlice.reducer;

//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectAgreementsFetch = createSelector(
    selectPaymentAgreements,
    (state) => state.fetch
);
