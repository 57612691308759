import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DataGrid,
    esES,
    gridPageCountSelector,
    gridPageSelector,
    GridToolbar,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Divider,
    IconButton,
    Pagination,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import {
    AddCircleOutline,
    BookmarkAddRounded,
    Delete,
    DeleteRounded,
    Edit,
} from '@mui/icons-material';
import {
    DeleteConcept,
    UpdateConcept,
} from '../../../store/concepts/operations';
import useThemeColors from '../../../../theme/themes';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { setStatusFilter } from '../../../store/concepts/ui';

import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import AgreementsModal from './AgreementsModal';
import {
    selectAgreements,
    selectTableData,
} from '../../../store/agreements/selectors';
import { render } from '@testing-library/react';
import ExportButton from '../../../components/exportButton';
import CustomPagination from '../../../components/CustomPagination';
import { DeleteAgreements } from '../../../store/agreements/operations';

const AgreementsTable = () => {
    ///////////// SHARED STATE /////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = useThemeColors();

    ///////////// SELECTORS /////////////
    const datatable = useSelector(selectTableData);

    ///////////// LOCAL STATE /////////////

    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    ///////////// HANDLERS /////////////
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    /////////// COLUMN CONFIG /////////////

    const columns = [
        {
            field: 'agreement_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => (
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    #{params.value}
                </Typography>
            ),
        },

        {
            field: 'created_at',
            headerName: 'Fecha de registro',
            flex: 2,
            minWidth: 300,
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 2.5,
            minWidth: 300,
            renderCell: (params) => {
                const user = params.row.user;
                const defaultAvatar = user?.gender === 'M' ? student : student2;
                const avatarSrc = user?.url_photo_profile || defaultAvatar;

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography variant="body1" noWrap>
                                {`${user?.name} ${user?.last_name} ${user?.second_last_name}`}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                ID: {user?.student_id}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                Teléfono: {user?.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'term_type',
            headerName: 'Tipo de plazo',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            minWidth: 200,

            renderCell: (params) => {
                const termTypeMapping = {
                    1: {
                        label: 'Semanal',
                        color: colors.velvetNight[500],
                        background: colors.velvetNight[100],
                    }, // Blue
                    2: {
                        label: 'Mensual',
                        color: colors.deepOcean[500],
                        background: colors.deepOcean[100],
                    }, // Green
                    3: {
                        label: 'Diario',
                        color: colors.twilight[500],
                        background: colors.twilight[100],
                    },
                };

                const termType = termTypeMapping[params.value];

                return (
                    <Box
                        px={2}
                        py={0.5}
                        borderRadius={1}
                        bgcolor={termType?.background || 'grey.200'}
                        color={termType?.color || 'grey.700'}
                        display="flex"
                        width={100}
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="bold">
                        <Typography variant="body2">
                            {termType?.label || 'Desconocido'}
                        </Typography>
                    </Box>
                );
            },
        },

        {
            field: 'current_term',
            headerName: 'Numero de plazo',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,

            renderCell: (params) => {
                return (
                    <Chip
                        label={`${params.value}/${params.row.remaining_terms}`}
                        color="primary"
                        variant="outlined"
                    />
                );
            },
        },
        {
            field: 'net_total',
            headerName: 'Total',
            flex: 0.5,
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'right',
                            color: colors.deepOcean[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}>{`$${params.value}`}</Typography>
                );
            },
        },

        {
            field: 'importe',
            headerName: 'Importe',
            flex: 0.5,
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'right',
                            color: colors.sunsetBlush[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}>{`$${params.value}`}</Typography>
                );
            },
        },

        {
            field: 'remaining',
            headerName: 'Restante',
            flex: 0.5,
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'right',

                            color: colors.sunbeam[700],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}>
                        {' '}
                        {`$${params.value}`}
                    </Typography>
                );
            },
        },
        {
            field: 'next_payment_date',
            headerName: 'Fecha del Próximo Pago',
            flex: 2,
            minWidth: 200,
            headerAlign: 'right',
            align: 'right',
        },
    ];

    const termTypeMapping = {
        1: 'semanal',
        2: 'mensual',
        3: 'diario',
    };

    const filteredRows = datatable
        .filter((row) => row.status !== false)
        .filter((row) => {
            const user = row.user;
            const searchString = `${user?.name} ${user?.last_name} ${
                user?.second_last_name
            } ${user?.student_id} ${user?.cellphone} ${
                termTypeMapping[row.term_type] || ''
            } ${row.concept?.net_total.toFixed(2)} ${row.current_term} ${
                row.concept.remaining
            }`.toLowerCase();
            return searchString.includes(searchTerm);
        })
        .map((row) => {
            const importe = (row.concept.net_total / row.term).toFixed(2);
            return {
                id: row.agreement_id,
                agreement_id: row.agreement_id,
                created_at: row.created_at,
                user: row.user,
                term_type: row.term_type,
                current_term: row.current_term,
                remaining_terms: row.term,
                net_total: row.concept?.net_total.toFixed(2),
                importe: importe,
                remaining: row.concept.remaining,
                next_payment_date: row.next_payment_date,
                date: row.created_at,
            };
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.neutral.main,
                    maxWidth: '100%',
                }}>
                <CardContent>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{
                                flexBasis: { xs: '90%', sm: '30%' },
                                minWidth: { xs: '90%', sm: '30%' },
                                mb: { xs: 2, sm: 0 },
                            }}>
                            Registrar un nuevo Convenio
                        </Button>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar un convenio"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{
                                minWidth: { xs: '90%', sm: '30%' },
                                backgroundColor: theme.palette.background.paper,
                                mr: -2,
                            }}
                        />
                    </Box>

                    <Box
                        flexWrap="wrap"
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                        gap={2}>
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            rowHeight={80}
                            autoHeight
                            pageSize={15}
                            rowsPerPageOptions={[10, 25, 50]}
                            components={{
                                Toolbar: () => (
                                    <ExportButton
                                        fileName="Convenios de pago"
                                        columns={columns}
                                        rows={filteredRows}
                                    />
                                ),
                                Pagination: CustomPagination,
                            }}
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                        />
                    </Box>
                </CardContent>
            </Card>

            <AgreementsModal open={open} onclose={handleClose} />
        </>
    );
};

export default AgreementsTable;
