import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';

const initialState = {
    Last_Student_With_Discount: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],  
    },
};

const cardsSlice = createSlice({
    name: 'Cards',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.Last_Student_With_Discount.fetch.didInvalidate = true; 
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchCardLastStudentWithDiscount.fulfilled, (state, action) => {
            state.Last_Student_With_Discount.fetch = {
                ...state.Last_Student_With_Discount.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };
            state.Last_Student_With_Discount.data = action.payload.data.components;
        });
        builder.addCase(fetchCardLastStudentWithDiscount.pending, (state) => {
            state.Last_Student_With_Discount.fetch.status = 'pending';
        });
        builder.addCase(fetchCardLastStudentWithDiscount.rejected, (state, action) => {
            state.Last_Student_With_Discount.fetch.status = 'rejected';
            // state.Last_Student_With_Discount.fetch.feedback = action.payload.feedback;  // Comentado como en chartsSlice
        });
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });
    },
});

export const { invalidate: invalidateCards } = cardsSlice.actions;

export default cardsSlice.reducer;

/////////  THUNKS  ////////////

export const fetchCardLastStudentWithDiscount = createAsyncThunk(
    'fetch/cardLastStudentWithDiscount',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'card',
                    idKey: 'last_student_with_discount',
                },
            });
            const lastStudentWithDiscount = response.data;
            return lastStudentWithDiscount;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectCardFetchState(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

///////////// SELECTORS  ////////////

export const selectCardsData = (state) =>
    state.paymentsModule.home.cards.Last_Student_With_Discount.data;

export const selectCardsStatus = (state) =>
    state.paymentsModule.home.cards.Last_Student_With_Discount.fetch.status;

export const selectCardFetchState = (state) =>
    state.paymentsModule.home.cards.Last_Student_With_Discount.fetch;



export const selectKpiStateLastStudentWithDiscount = (state) =>
    state.paymentsModule.home.cards.Last_Student_With_Discount.fetch.status;
