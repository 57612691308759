import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Avatar,
    Grid,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import male from '../images/male.png';
import female from '../images/female.png';
import useThemeColors from '../../../../theme/themes';

const WelcomeCard = () => {
    const auth = useAuth();
    const user = auth.getUser();
    const colors = useThemeColors();
    const avatarSrc = user.gender === 'M' ? female : male;
    const genderText = user.gender === 'M' ? 'Bienvenida' : 'Bienvenido';

    const getMessageOfTheDay = () => {
        const dayOfWeek = new Date().getDay();
        switch (dayOfWeek) {
            case 1:
                return 'La educación financiera es clave para asegurar un futuro brillante.';
            case 2:
                return 'Invertir en conocimiento siempre da los mejores intereses.';
            case 3:
                return 'Cada paso hacia la mejora personal también es un paso hacia un futuro más própero.';
            case 4:
                return 'El optimismo es el motor que impulsa la superación en cualquier circunstancia.';
            case 5:
                return 'La gestión efectiva de recursos es la base del éxito académico y financiero.';
            default:
                return 'Hoy es un buen día para aprender algo nuevo y mejorar.';
        }
    };

    return (
        <Box
            className="Special-card"
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                height: '83%',
            }}>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    overflow: 'visible', // Permite que el contenido sobresalga
                }}>
                <CardContent
                    className="content-card"
                    sx={{ position: 'relative' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9} sm={12}>
                            <Box>
                                <Typography
                                    variant="h6"
                                    className="card-title"
                                    sx={{
                                        wordWrap: 'break-word',
                                        marginRight: {
                                            xs: '120px',
                                            md: '160px',
                                        },
                                        fontSize: '1.5rem',
                                        fontWeight: 700,
                                    }}>
                                    ¡{genderText}, {user.name}!
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="card-message"
                                    sx={{
                                        wordWrap: 'break-word',
                                        marginRight: '120px',
                                        color: colors.grey[500],
                                        fontWeight: 200,
                                        fontSize: {
                                            xs: '18px',
                                            sm: '12px',
                                            md: '14px',
                                        },
                                    }}>
                                    {getMessageOfTheDay()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sm={9}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                position: 'relative',
                            }}>
                            <Avatar
                                src={avatarSrc}
                                className="user-avatar"
                                sx={{
                                    width: {
                                        xs: '155px',
                                        sm: '165px',
                                        md: '170px',
                                    },
                                    height: {
                                        xs: '175px',
                                        sm: '185px',
                                        md: '190px',
                                    },
                                    position: 'absolute',
                                    right: {
                                        xs: '-20px',
                                        sm: '-150px',
                                        md: '-30px',
                                    },
                                    top: '50%',
                                    transform: {
                                        xs: 'translateY(-110%)',
                                        sm: 'translateY(-90%)',
                                        md: 'translateY(-60%)',
                                        lg: 'translateY(-60%)',
                                    },
                                    zIndex: 1,
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default WelcomeCard;
