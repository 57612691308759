import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
    useTheme,
    Avatar,
    Tooltip,
    Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UpdateConcept } from '../../../store/concepts/operations';
import { Delete } from '@mui/icons-material';
import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import { useFeedback } from '../../../../hooks';

const ModalUpdateConcept = ({ open, onclose, selectedRows }) => {
    ///////////////// LOCAL STATE /////////////
    const [concepts, setConcepts] = useState(selectedRows);
    const [initialValues, setInitialValues] = useState({});
    ///////////// SHARED STATE /////////////
    const theme = useTheme();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////// FORM SETUP /////////////
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(
            concepts.reduce((acc, concept) => {
                acc[concept.concept_id] = Yup.object().shape({
                    description: Yup.string().required(
                        'Descripción es requerida'
                    ),
                    limit_date: Yup.date()
                        .min(
                            new Date().toISOString().split('T')[0],
                            'La fecha límite no puede ser anterior a hoy'
                        )
                        .required('Fecha límite es requerida'),
                });
                return acc;
            }, {})
        ),
        onSubmit: async (values) => {
            const updatedConcepts = Object.keys(values).map((id) => ({
                ...values[id],
                concept_id: Number(id),
            }));
            await dispatch(UpdateConcept(updatedConcepts))
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Concepto Actualizado correctamente',
                    });
                    handleClose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    ///////////// USE EFFECTS /////////////
    useEffect(() => {
        setConcepts(selectedRows);
        const newInitialValues = selectedRows.reduce(
            (acc, concept) => ({
                ...acc,
                [concept.concept_id]: {
                    description: concept.description || '',
                    limit_date: concept.limit_date || '',
                },
            }),
            {}
        );
        setInitialValues(newInitialValues);
        formik.resetForm({ values: newInitialValues });
    }, [selectedRows]);

    ///////////// HANDLERS /////////////
    const handleFieldChange = (conceptId, field) => (event) => {
        const newValue = event.target.value;
        formik.setFieldValue(`${conceptId}.${field}`, newValue);
    };

    const handleRemoveConcept = (conceptId) => () => {
        setConcepts(concepts.filter((c) => c.concept_id !== conceptId));
        formik.setFieldValue(conceptId, undefined);
    };

    const handleClose = () => {
        formik.resetForm();
        onclose();
    };

    ///////////// RENDER /////////////
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    paddingBottom: 5,
                    position: 'relative',
                }}>
                <Typography variant="h4" component="div">
                    Actualizar conceptos
                </Typography>
                <Typography variant="body2">
                    Aquí podrás actualizar los conceptos
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {concepts.map((concept) => {
                    const defaultAvatar =
                        concept.user.gender === 'M' ? student : student2;
                    const avatarSrc =
                        concept.user.url_photo_profile || defaultAvatar;

                    return (
                        <>
                            <Box key={concept.concept_id} sx={{ mb: 3 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}>
                                    <Avatar
                                        src={avatarSrc}
                                        alt={`${concept.user.name} ${concept.user.last_name}`}
                                        sx={{
                                            width: 56,
                                            height: 56,
                                            mr: 2,
                                        }}
                                    />
                                    <Box>
                                        <Typography variant="body1">
                                            {`${concept.user.name} ${concept.user.last_name}`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            noWrap>
                                            ID: {concept.user.student_id}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            noWrap>
                                            Teléfono:
                                            {concept.user.cellphone}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={handleRemoveConcept(
                                            concept.concept_id
                                        )}
                                        sx={{
                                            ml: 'auto',
                                            color: theme.palette.error.main,
                                        }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ mb: 2 }} />
                                <TextField
                                    label="Descripción"
                                    value={
                                        formik.values[concept.concept_id]
                                            ?.description || ''
                                    }
                                    onChange={formik.handleChange}
                                    name={`${concept.concept_id}.description`}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                    error={Boolean(
                                        formik.touched[concept.concept_id]
                                            ?.description &&
                                            formik.errors[concept.concept_id]
                                                ?.description
                                    )}
                                    helperText={
                                        formik.touched[concept.concept_id]
                                            ?.description &&
                                        formik.errors[concept.concept_id]
                                            ?.description
                                    }
                                />

                                <TextField
                                    label="Fecha límite"
                                    type="date"
                                    value={
                                        formik.values[concept.concept_id]
                                            ?.limit_date || ''
                                    }
                                    onChange={formik.handleChange}
                                    name={`${concept.concept_id}.limit_date`}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ mb: 2 }}
                                    error={Boolean(
                                        formik.touched[concept.concept_id]
                                            ?.limit_date &&
                                            formik.errors[concept.concept_id]
                                                ?.limit_date
                                    )}
                                    helperText={
                                        formik.touched[concept.concept_id]
                                            ?.limit_date &&
                                        formik.errors[concept.concept_id]
                                            ?.limit_date
                                    }
                                    inputProps={{
                                        min: new Date()
                                            .toISOString()
                                            .split('T')[0],
                                    }}
                                />
                            </Box>
                        </>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={formik.handleSubmit}
                        disabled={
                            formik.isSubmitting ||
                            !formik.isValid ||
                            !formik.dirty
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Actualizar'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateConcept;
