import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import {
    fetchTopDebtors,
    selectTopsData,
} from '../../../store/home/tops/index';
import { GroupRemove } from '@mui/icons-material';
import { useEffect } from 'react';
import femaleAvatar from '../../../../assets/images/default-image-niña.svg';
import maleAvatar from '../../../../assets/images/default-image-niño.svg';
import { minWidth } from '@mui/system';

const DebtorTable = () => {
    const theme = useTheme();
    const topDebtorsData = Object.values(useSelector(selectTopsData));
    const schoolId = useSelector((state) => state.auth.user.school_id);
    const dispatch = useDispatch();
    const getAvatarSrc = (row) => {
        if (row.foto) {
            return row.foto;
        } else if (row.gender === 'M') {
            return femaleAvatar;
        } else {
            return maleAvatar;
        }
    };
    useEffect(() => {
        if (schoolId) {
            dispatch(fetchTopDebtors(schoolId));
        }
    }, [dispatch, schoolId]);

    const rows = topDebtorsData
        .map((row) => ({
            id: row.user_id,
            user: row.label,
            foto: row.foto,
            cellphone: row.cellphone,
            Recargo: row.recargo,
            Descuento: row.descuento,
            Total: row.total_a_pagar,
        }))
        .slice(0, 5);

    const columns = [
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 0.15,
            minWidth: 400,
            renderCell: (params) => (
                <Box display="flex" alignItems="center" mb="2" mt="2">
                    <Avatar
                        sx={{ marginRight: 2 }}
                        src={getAvatarSrc(params.row)}
                    />
                    <Box>
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{ mt: 0.5 }}>
                            {params.row.user}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            noWrap
                            sx={{ mt: 0.3 }}>
                            ID: {params.row.id}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            noWrap
                            sx={{ mt: 0.3 }}>
                            Teléfono: {params.row.cellphone}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: 'Recargo',
            headerName: 'Recargo',
            flex: 0.1,
            minWidth: 80,
            renderCell: (params) => (
                <Typography fontWeight="bold" style={{ color: 'red' }}>
                    +${params.value}
                </Typography>
            ),
        },
        {
            field: 'Descuento',
            headerName: 'Descuento',
            flex: 0.1,
            minWidth: 80,
            renderCell: (params) => (
                <Typography fontWeight="bold" style={{ color: 'green' }}>
                    -${params.value}
                </Typography>
            ),
        },
        {
            field: 'Total',
            headerName: 'Total',
            flex: 0.1,
            minWidth: 80,
            renderCell: (params) => (
                <Typography fontWeight="bold" style={{ color: 'blue' }}>
                    ${params.value}
                </Typography>
            ),
        },
    ];

    const calculateRowHeight = () => {
        const cardHeight = 350;
        const numRows = rows.length;
        const rowHeight = cardHeight / (numRows > 0 ? numRows : 1);
        return rowHeight > 50 ? rowHeight : 50;
    };

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.neutral.main,
                maxWidth: '100%',
                maxHeight: '100%',
                mb: 2,
            }}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={3}>
                <Box
                    sx={{
                        backgroundColor: '#FFA500',
                        borderRadius: '100%',
                        width: '2rem',
                        height: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 1.5,
                    }}>
                    <GroupRemove
                        sx={{
                            width: '1.5rem',
                            color: '#ffffff',
                        }}
                    />
                </Box>
                <Typography variant="h6" className="card-value">
                    Deudores
                </Typography>
            </Box>

            <CardContent>
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: '2fr', md: 'auto' }}
                    gap={2}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        disableColumnReorder
                        hideFooter
                        getRowHeight={calculateRowHeight}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowSpacingType="border"
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export default DebtorTable;
