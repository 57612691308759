import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Button,
    IconButton,
    Avatar,
    Typography,
    TextField,
    MenuItem,
    Tooltip,
    Menu,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    Chip,
    Link,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    MoreVert as MoreVertIcon,
    MonetizationOn as CashIcon,
    AccountBalance as TransactionIcon,
    Delete,
} from '@mui/icons-material';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectTableData } from '../../../store/income/selectors';
import { fetchExport, fetchIncome } from '../../../store/income/fetchSlice';
import IncomeModal from './ModalIncome';
// import IncomeUpdate from './IncomeUpdate'; // Import the IncomeUpdate component
import {
    AddCircleOutline,
    BookmarkAddRounded,
    Edit,
} from '@mui/icons-material';
import CustomPagination from '../../../components/CustomPagination';

import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import useThemeColors from '../../../../theme/themes';
import ExportButton from '../../../components/exportButton';
import PaymentsIcon from '@mui/icons-material/Payments';
import { DeletePayment } from '../../../store/income/operationSlice';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentReturnedTwoToneIcon from '@mui/icons-material/AssignmentReturnedTwoTone';

const IncomeTable = () => {
    /////////////////////LOCAL STATE/////////////////////

    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [currentPaymentId, setCurrentPaymentId] = useState(null);

    /////////////////////SHARE STATE/////////////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const theme = useTheme();
    const colors = useThemeColors();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();
    const incomeData = useSelector(selectTableData);

    /////////////////////FUNCTIONS/////////////////////

    const filterRows = (rows, term) => {
        if (!term) return rows;
        return rows.filter((row) => {
            const termLower = term.toLowerCase();
            return (
                row.payment_id.toString().toLowerCase().includes(termLower) ||
                `${row.user.name} ${row.user.last_name} ${row.user.second_last_name}`
                    .toLowerCase()
                    .includes(termLower) ||
                row.concept.toLowerCase().includes(termLower) ||
                row.amount.toString().toLowerCase().includes(termLower) ||
                (row.payment_method === 1 && 'efectivo'.includes(termLower)) ||
                (row.payment_method === 2 && 'transacción'.includes(termLower))
            );
        });
    };

    /////////////////////HANDLERS/////////////////////

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUpdateOpen = () => {
        setUpdateOpen(true);
        handleMenuClose();
    };

    const handleUpdateClose = () => {
        setUpdateOpen(false);
    };

    const handleDeleteOpen = (id) => {
        setCurrentPaymentId(id);
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen(false);
        setCurrentPaymentId(null);
    };

    const handleDeleteConfirm = async () => {
        if (currentPaymentId) {
            await dispatch(DeletePayment(currentPaymentId))
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Pago eliminado correctamente',
                    });
                })
                .catch((error) => {
                    feedbackApp.showFeedback({ title: error.feedback.title });
                });
            setDeleteDialogOpen(false);
            setCurrentPaymentId(null); // Clear the payment ID after deletion
        }
    };
    ///////////////////// COLUMNS /////////////////////

    const columns = [
        {
            field: 'payment_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                        #{params.value}
                    </Typography>
                );
            },
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 3,
            minWidth: 400,
            renderCell: (params) => {
                const user = params.row.user;
                const defaultAvatar = user.gender === 'M' ? student : student2;
                const avatarSrc = user.url_photo_profile || defaultAvatar;

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography variant="body1" noWrap>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                ID: {user.student_id}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            valueGetter: (params) =>
                `${params.row.user.name} ${params.row.user.last_name} ${params.row.user.second_last_name}`,
        },

        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 2,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: (params) => {
                const title = params.value;
                const description = params.row.description;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.deepOcean[500],
                            }}
                            noWrap>
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[600],
                            }}
                            noWrap>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },

        {
            field: 'payment_method',
            headerName: 'Tipo de plazo',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,

            renderCell: (params) => {
                const termTypeMapping = {
                    1: {
                        label: 'Efectivo',
                        icon: <PaymentsIcon />,
                        color: colors.velvetNight[500],
                        background: colors.velvetNight[100],
                    },
                    2: {
                        label: 'Transferencia',
                        icon: <AccountBalanceIcon />,
                        color: colors.sunsetBlush[500],
                        background: colors.sunsetBlush[100],
                    },
                    3: {
                        label: 'Tarjeta de crédito/débito',
                        icon: <CreditCardIcon />,
                        color: colors.green[500],
                        background: colors.green[200],
                    },
                    4: {
                        label: 'Tarjeta prepaga',
                        icon: <CardGiftcardIcon />,
                        color: colors.twilight[500],
                        background: colors.twilight[100],
                    },
                    5: {
                        label: 'Domiciliación bancaria ',
                        icon: <AccountBalanceWalletIcon />,
                        color: colors.coral[500],
                        background: colors.coral[100],
                    },
                };

                const termType = termTypeMapping[params.value];

                return (
                    <Box
                        px={2}
                        py={0.5}
                        borderRadius={1}
                        bgcolor={termType?.background || 'grey.200'}
                        color={termType?.color || 'grey.700'}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={120}
                        fontWeight="bold">
                        <Tooltip title={termType?.label || 'Desconocido'}>
                            {termType?.icon}
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Importe',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,

            renderCell: (params) => (
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 'bold',
                    }}>
                    $ {params.value}
                </Typography>
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 100,
            renderCell: (params) => {
                const handleDownload = async () => {
                    await dispatch(
                        fetchExport({
                            schoolId,
                            paymentId: params.row.payment_id,
                            terms: params.row.current_term,
                        })
                    )
                        .unwrap()
                        .then((result) => {
                            feedbackApp.showFeedback({
                                title: 'Recibo descargado correctamente',
                            });
                        })
                        .catch((error) => {
                            feedbackApp.showFeedback({
                                title: 'Ocurrió un error al descargar el recibo',
                            });
                        });
                };

                return (
                    <>
                        <Tooltip title={'Eliminar'} arrow>
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    handleDeleteOpen(params.row.payment_id)
                                }
                                sx={{ color: colors.red[500] }}>
                                <DeleteSweepTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Descargar Recibo" arrow>
                            <IconButton
                                color="primary"
                                onClick={handleDownload}
                                sx={{ color: colors.lavender[500] }}>
                                <AssignmentReturnedTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const filteredRows = filterRows(
        incomeData.map((row) => ({
            id: row.payment_id,
            payment_id: row.payment_id,
            user: row.user,
            payment_method: row.payment_method,
            concept: row.catalogConcept.concept,
            description: row.concept.description,
            amount: row.amount,
            date: row.created_at,
            current_term: row.agreement?.current_term ?? 1,
            total_terms: row.agreement?.term ?? 1,
        })),
        searchTerm
    ).sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.neutral.main,
                    maxWidth: '100%',
                }}>
                <CardContent>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{
                                flexBasis: { xs: '90%', sm: '30%' },
                                minWidth: { xs: '90%', sm: '30%' },
                                mb: { xs: 2, sm: 0 },
                            }}>
                            Registrar un nuevo Pago
                        </Button>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar un Pago"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{
                                minWidth: { xs: '90%', sm: '30%' },
                                backgroundColor: theme.palette.background.paper,
                                mr: -2,
                            }}
                        />
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                        gap={2}>
                        <DataGrid
                            rowHeight={80}
                            rows={filteredRows}
                            columns={columns}
                            autoHeight
                            pageSize={15}
                            rowsPerPageOptions={[10, 25, 50]}
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            components={{
                                Toolbar: () => (
                                    <ExportButton fileName="Pagos" />
                                ),
                                Pagination: CustomPagination,
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

            <IncomeModal open={open} onclose={handleClose} />

            <Dialog
                sx={{ '& .MuiDialog-paper': { padding: 3, borderRadius: 2 } }}
                open={deleteDialogOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {'Confirmar eliminación'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de que deseas eliminar este pago? Esta
                        acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="primary"
                        autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default IncomeTable;
