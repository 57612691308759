import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';

const initialState = {
    Top_Debtors: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
};

const topsSlice = createSlice({
    name: 'tops',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.Top_Debtors.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTopDebtors.fulfilled, (state, action) => {
            state.Top_Debtors.fetch = {
                ...state.Top_Debtors.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                didInvalidate: false,
                status: 'fulfilled',
            };

            state.Top_Debtors.data = action.payload.debtors;
        });
        builder.addCase(fetchTopDebtors.pending, (state) => {
            state.Top_Debtors.fetch.status = 'pending';
        });
        builder.addCase(fetchTopDebtors.rejected, (state, action) => {
            state.Top_Debtors.fetch.status = 'rejected';
        });
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });
    },
});

export const { invalidate } = topsSlice.actions;

export default topsSlice.reducer;

///////////// THUNKS  ////////////

export const fetchTopDebtors = createAsyncThunk(
    'fetch/topDebtors',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'top_debtors',
                },
            });

            const debtors = response.data.data.components.value;

            return { debtors };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectTopFetchState(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

///////////// SELECTORS  ////////////

export const selectTopsData = (state) =>
    state.paymentsModule.home.tops.Top_Debtors.data;

export const selectTopsStatus = (state) =>
    state.paymentsModule.home.tops.Top_Debtors.fetch.status;

export const selectTopFetchState = (state) =>
    state.paymentsModule.home.tops.Top_Debtors.fetch;

export const selectStateTopDebtors = (state) =>
    state.paymentsModule.home.tops.Top_Debtors.fetch.status;