import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';

const initialState = {
    Total_Discounts_Lost: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    Total_Gains: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    Concepts_Without_Payments: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
};

const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.Total_Discounts_Lost.fetch.didInvalidate = true;
            state.Total_Gains.fetch.didInvalidate = true;
            state.Concepts_Without_Payments.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        // TotalDiscountLoss
        builder.addCase(fetchTotalDiscountLoss.fulfilled, (state, action) => {
            state.Total_Discounts_Lost.fetch = {
                ...state.Total_Discounts_Lost.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };
            state.Total_Discounts_Lost.data = action.payload.data.components;
        });
        builder.addCase(fetchTotalDiscountLoss.pending, (state) => {
            state.Total_Discounts_Lost.fetch.status = 'pending';
        });
        builder.addCase(fetchTotalDiscountLoss.rejected, (state, action) => {
            state.Total_Discounts_Lost.fetch.status = 'rejected';
        });

        // ConceptsWithoutPayment
        builder.addCase(fetchConceptsWithoutPayment.fulfilled, (state, action) => {
            state.Concepts_Without_Payments.fetch = {
                ...state.Concepts_Without_Payments.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };
            state.Concepts_Without_Payments.data = action.payload.data.components;
        });
        builder.addCase(fetchConceptsWithoutPayment.pending, (state) => {
            state.Concepts_Without_Payments.fetch.status = 'pending';
        });
        builder.addCase(fetchConceptsWithoutPayment.rejected, (state, action) => {
            state.Concepts_Without_Payments.fetch.status = 'rejected';
        });

        // TotalGains
        builder.addCase(fetchTotalGains.fulfilled, (state, action) => {
            state.Total_Gains.fetch = {
                ...state.Total_Gains.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };
            state.Total_Gains.data = action.payload.data.components;
        });
        builder.addCase(fetchTotalGains.pending, (state) => {
            state.Total_Gains.fetch.status = 'pending';
        });
        builder.addCase(fetchTotalGains.rejected, (state, action) => {
            state.Total_Gains.fetch.status = 'rejected';
        });

        // Reset state
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });
    },
});

export const { invalidate: invalidateKpis } = kpiSlice.actions;

export default kpiSlice.reducer;

///////// THUNKS  ////////////

export const fetchTotalDiscountLoss = createAsyncThunk(
    'fetch/totalDiscountLoss',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_discount_loss',
                },
            });
            const discountLoss = response.data;
            return discountLoss;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectKpiFetchTotalDiscountsLoss(getState());
            const valid = expireIn > Date.now();
            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchConceptsWithoutPayment = createAsyncThunk(
    'fetch/conceptsWithoutPayment',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_concepts_wiouth_payments',
                },
            });
            const conceptsWithoutPayment = response.data;
            return conceptsWithoutPayment;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectKpiFetchConceptsWithoutPayment(getState());
            const valid = expireIn > Date.now();
            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchTotalGains = createAsyncThunk(
    'fetch/totalGains',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_gains',
                },
            });
            const totalGainsData = response.data;
            return totalGainsData;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectKpiFetchTotalGains(getState());
            const valid = expireIn > Date.now();
            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

///////////// SELECTORS  ////////////

export const selectKpi = (state) => state.paymentsModule.home.kpis;

export const selectKpiStatus = (state) => selectKpi(state).status;

export const selectConceptsWithoutPayment = createSelector(
    [selectKpi],
    (kpi) => kpi.Concepts_Without_Payments.data
);

export const selectTotalGains = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Gains.data
);

export const selectTotalDiscountLoss = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Discounts_Lost.data
);

export const selectKpiFetchConceptsWithoutPayment = (state) =>
    state.paymentsModule.home.kpis.Concepts_Without_Payments.fetch;

export const selectKpiFetchTotalGains = (state) =>
    state.paymentsModule.home.kpis.Total_Gains.fetch;

export const selectKpiFetchTotalDiscountsLoss = (state) =>
    state.paymentsModule.home.kpis.Total_Discounts_Lost.fetch;

    // indiividuales

export const selectKpiStateTotalGains = (state) =>
    state.paymentsModule.home.kpis.Total_Gains.fetch.status;

export const selectKpiStateConceptsWithoutPayment = (state) =>
    state.paymentsModule.home.kpis.Concepts_Without_Payments.fetch.status;


