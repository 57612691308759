import React, { useEffect } from 'react';
import { Card, CardContent, Grid, useMediaQuery, Typography, Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import useThemeColors from '../../../../theme/themes';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMonthlyPaymentsCount, selectMonthlyPaymentsCount } from '../../../store/home/tables';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

const MonthlyIncome = () => {

    const dispatch = useDispatch();
    const schoolId = useSelector((state) => state.auth.user.school_id);
    const colors = useThemeColors();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (schoolId) {
            dispatch(fetchMonthlyPaymentsCount(schoolId));
        }
    }, [dispatch, schoolId]);

    const monthlyPaymentsData = useSelector(selectMonthlyPaymentsCount);

    const rows = monthlyPaymentsData?.value?.map((item) => ({
        label: item.label.substring(0, 3), 
        value: parseFloat(item.value) || 0,
    })) || [];

    const maxValue = Math.max(...rows.map((item) => item.value), 0);
    const totalValue = rows.reduce((acc, item) => acc + item.value, 0);
    const currentYear = new Date().getFullYear();

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                height: '650px',
                width: '100%',
                padding: 2,
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Box display="flex" alignItems="center" mb={2}>
                    <Box
                        sx={{
                            backgroundColor: colors.green[600],
                            borderRadius: '100%',
                            width: { xs: '1.5rem', md: '2rem' },
                            height: { xs: '1.5rem', md: '2rem' },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: { xs: 1, md: 1.5 },
                        }}
                    >
                        <BarChartOutlinedIcon
                            sx={{
                                width: { xs: '1rem', md: '1.5rem' },
                                color: '#ffffff',
                            }}
                        />
                    </Box>
                    <Typography variant="h6" className="card-value">
                        Pagos por Mes
                    </Typography>
                </Box>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1, mt: 2 }}>
                    Año: {currentYear}
                </Typography>
            </Box>
            <CardContent sx={{ padding: isSmallScreen ? '8px' : '16px' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid
                        item
                        xs={12}
                        sx={{
                            height: isSmallScreen ? '250px' : '400px',
                        }}
                    >
                        {rows.length > 0 ? (
                            <div style={{ height: '100%', width: '100%' }}>
                                <ResponsiveBar
                                    data={rows}
                                    keys={['value']}
                                    indexBy="label"
                                    layout="horizontal"
                                    margin={{
                                        top: 30,
                                        right: isSmallScreen ? 10 : 20,
                                        bottom: isSmallScreen ? 70 : 80,
                                        left: 70,
                                    }}
                                    padding={0.3}
                                    colors={(d) => {
                                        const colorArray = [
                                            colors.green[500],
                                            colors.sunbeam[500],
                                            colors.velvetNight[500],
                                            colors.calmWaters[500],
                                            colors.red[500]
                                        ];
                                        return colorArray[d.index % colorArray.length];
                                    }}
                                    borderRadius={4}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: -30,
                                        legend: '$',
                                        legendPosition: 'middle',
                                        legendOffset: 60,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    enableGridX={true}
                                    enableGridY={false}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor="white"
                                    tooltip={({ indexValue, value, color }) => (
                                        <div
                                            style={{
                                                padding: '12px',
                                                background: '#fff',
                                                border: `1px solid ${color}`,
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <strong>{indexValue}</strong>: {value}
                                        </div>
                                    )}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    minValue={0}
                                    maxValue={maxValue < 10 ? 10 : 'auto'}
                                />
                            </div>
                        ) : (
                            <Typography variant="h6" align="center">
                                Sin información para la gráfica
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            {rows.length > 0 && (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" fontWeight="bold">
                        Total: ${totalValue.toFixed(2)}
                    </Typography>
                </Box>
            )}
        </Card>
    );
};

export default MonthlyIncome;
