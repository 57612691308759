import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLastStudentsWithPayments,
    selectLastStudentsWithPayments,
} from '../../../store/home/tables';
import { useTheme } from '@emotion/react';
import { Avatar } from 'antd';
import { CardMembershipOutlined } from '@mui/icons-material';
import femaleAvatar from '../../../../assets/images/default-image-niña.svg';
import maleAvatar from '../../../../assets/images/default-image-niño.svg';

const TopTransactionsCard = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const schoolId = useSelector((state) => state.auth.user.school_id);
    const lastStudentsWithPayments = useSelector(
        selectLastStudentsWithPayments
    ).value;

    const getChipProps = (concept) => {
        switch (concept) {
            default:
                return { color: 'default', label: concept };
        }
    };

    useEffect(() => {
        if (schoolId) {
            dispatch(fetchLastStudentsWithPayments(schoolId));
        }
    }, [dispatch, schoolId]);

    const rows = Array.isArray(lastStudentsWithPayments)
        ? lastStudentsWithPayments.slice(0, 5)
        : [];

    const getAvatarSrc = (row) => {
        if (row.photo) {
            return row.photo;
        } else if (row.gener === 'M') {
            return femaleAvatar;
        } else {
            return maleAvatar;
        }
    };

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.neutral.main,
                maxWidth: '100%',
                mb: 2,
                height: '650px',
            }}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={2}>
                <Box
                    sx={{
                        backgroundColor: '#4d82bc',
                        borderRadius: '100%',
                        width: '2rem',
                        height: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 1.5,
                    }}>
                    <CardMembershipOutlined
                        sx={{
                            width: '1.5rem',
                            color: '#ffffff',
                        }}
                    />
                </Box>
                <Typography variant="h6" className="card-value">
                    Últimos Pagos
                </Typography>
            </Box>
            <CardContent sx={{ overflowX: 'auto' }}>
                <Box sx={{ minWidth: '650px' }}>
                    {rows.length > 0 ? (
                        rows.map((row) => (
                            <Box
                                key={row.label}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={3.5}
                                mt={3.5}
                                minWidth={'3.5'}
                                sx={{
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                }}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    flex={2}>
                                    <Avatar
                                        src={getAvatarSrc(row)}
                                        sx={{
                                            width: 60,
                                            height: 60,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold">
                                            {row.label}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            {row.date}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flex={1}>
                                    <Chip
                                        label={getChipProps(row.concept).label}
                                        color={getChipProps(row.concept).color}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: {
                                                xs: '0.75rem',
                                                sm: '0.875rem',
                                                md: '1rem',
                                            },
                                            padding: {
                                                xs: '6px 10px', // Ajuste de padding para pantallas pequeñas
                                                sm: '8px 12px',
                                                md: '10px 14px', // Padding más grande en pantallas medianas y grandes
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flex={1}>
                                    <Typography
                                        variant="body1"
                                        color="green"
                                        fontWeight="bold">
                                        +${row.value}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            sx={{ textAlign: 'center', color: '#999', mt: 2 }}>
                            No hay pagos recientes
                        </Typography>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default TopTransactionsCard;
