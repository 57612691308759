import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsDiversity,
} from '../../../store/home/fetchSlice';
import { getStudentsDiversity } from '../../../store/home/thunks';
import {
    SkeletongGrafics,
    SkeletonLongCard,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeStudentsDiversityStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const StudentsDiversity = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeStudentsDiversityStatusServer
    );

    const diversity = useFetchResource(
        () =>
            getStudentsDiversity({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsDiversity,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsDiversity({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsDiversity,
            [cycleSelected, schoolId]
        );
    };

    const processDiversityData = (data) => {
        if (!data.value) {
            return [];
        }

        const { labels, data: values } = data.value;
        return labels.map((label, index) => ({
            label,
            Estudiantes: values[index],
        }));
    };

    const diversityData = diversity ? processDiversityData(diversity) : [];

    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonLongCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '410px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent
                            sx={{
                                width: '100%',
                                padding: '16px',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {diversity?.title || 'Promedio por grado'}
                            </Typography>
                            <Box height={300}>
                                <ResponsiveBar
                                    data={diversityData}
                                    keys={['Estudiantes']}
                                    indexBy="label"
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 60,
                                        left: 40,
                                    }}
                                    padding={0.5}
                                    colors={(bar) => colors[bar.index]}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 45,
                                        legendPosition: 'middle',
                                        legendOffset: 32,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -50,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor="white"
                                    layers={[
                                        'grid',
                                        'axes',
                                        'bars',
                                        'markers',
                                        'legends',
                                        (props) =>
                                            props.bars.map((bar) => {
                                                const isSmallValue =
                                                    bar.data.value <= 10;

                                                return (
                                                    <g
                                                        transform={`translate(${bar.x}, ${bar.y})`}
                                                        key={bar.key}
                                                    >
                                                        <rect
                                                            width={bar.width}
                                                            height={bar.height}
                                                            fill={bar.color}
                                                        />
                                                        {bar.data.value >
                                                            10 && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={
                                                                    bar.height /
                                                                    2
                                                                }
                                                                textAnchor="middle"
                                                                dominantBaseline="central"
                                                                style={{
                                                                    fill: 'white',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                        {isSmallValue && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={-5}
                                                                textAnchor="middle"
                                                                style={{
                                                                    fill: 'black',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                    </g>
                                                );
                                            }),
                                    ]}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 12 },
                                            },
                                        },
                                        labels: {
                                            text: {
                                                fontSize: 16,
                                                fill: 'white',
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 12,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default StudentsDiversity;
