import { createSelector } from '@reduxjs/toolkit';
import { selectStudents } from '../../../store/slices/entities/students';
import { selectCatConcepts } from '../cat_concepts/selectors';
import { selectPartialities } from '../partialities/selectors';

/**
 * Selector para recuperar home de reportes
 */
export const selectPaymentAgreements = (state) =>
    state.paymentsModule.agreements;

export const selectAgreements = (state) =>
    Object.values(state.entities.agreements.entities);

const selectConcepts = (state) => state.entities.concepts.entities;

export const selectTableData = createSelector(
    [selectAgreements, selectConcepts, selectStudents, selectPartialities],
    (agremmets, concepts, student) => {
        return agremmets.map((agremmet) => {
            const userarray = Object.values(student);
            const conceptArray = Object.values(concepts);

            const user = userarray.find(
                (student) => agremmet.user_id === student.student_id
            );

            const concept = conceptArray.find(
                (concept) => concept.concept_id === agremmet.concept_id
            );

            return {
                ...agremmet,
                concept,
                user,
            };
        });
    }
);
